const LOCATIONS = [
  {
    value: '1',
    label: 'Lougheed Skytrain Station',
  },
  {
    value: '2',
    label: 'UBC',
  },
  {
    value: '3',
    label: 'SFU',
  },
  {
    value: '4',
    label: 'Marine Drive',
  },
  {
    value: '5',
    label: 'Joyce Collingwood',
  },
];

const SIZES = [
  {
    value: '1',
    label: 'S',
  },
  {
    value: '2',
    label: 'M',
  },
  {
    value: '3',
    label: 'L',
  },
  {
    value: '4',
    label: 'XL',
  },
  {
    value: '5',
    label: 'XXL',
  },
];

const MODELS = [
  {
    value: '1',
    label: 'KELANA - Waroeng Cak Timmies Hoodie',
    price: 35,
    image:
      'https://permikawebsite.s3.us-west-2.amazonaws.com/assets/merchandise/hoodieBlackFront.webp',
  },
  {
    value: '2',
    label: 'KELANA - Anak Rantau Hoodie',
    price: 35,
    image:
      'https://permikawebsite.s3.us-west-2.amazonaws.com/assets/merchandise/hoodieWhiteFront.webp',
  },
  {
    value: '3',
    label: 'KELANA - Anak Rantau T-Shirt',
    price: 20,
    image:
      'https://permikawebsite.s3.us-west-2.amazonaws.com/assets/merchandise/shirtFront.webp',
  },
];

const DISCOUNT = 0.1;
const DISCOUNT_DEADLINE = '2024-02-02 00:00';

export { LOCATIONS, SIZES, MODELS, DISCOUNT, DISCOUNT_DEADLINE };
