import { UpcomingPage } from '../../shared/layouts/UpcomingPage';
// import { Header } from './components/Header';

export const Blog = () => {
  return (
    <>
      {/* <Header className="pt-navbar ml-all mr-[10%]" style={{}} /> */}
      <UpcomingPage />
    </>
  );
};
